<template>
  <div>
    <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
      <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
      <v-btn dark text @click="snackbar = false">Close</v-btn>
    </v-snackbar>
    
    <br />

    <v-card class="card">
      <v-card-title class="heading justify-center"
        >Edtion Wise Report</v-card-title
      >
      <div class="">
        <div class="add-section">
          <div class="d-flex">
            <!--<v-col>
              <label class="add-text">Department</label>{{ dept }}
              <v-autocomplete
                v-model="dept"
                placeholder="Select Department"
                :items="dept_data"
                item-text="name"
                item-value="id"
                outlined
                dense
                class="text"
                :rules="[rules.required]"
                clearable
                required
              ></v-autocomplete>
            </v-col>-->
            <v-col>
              <label class="add-text">Series</label>
              <v-autocomplete
                v-model="series"
                placeholder="Select Series"
                :items="series_data"
                item-text="name"
                item-value="id"
                outlined
                dense
                class="text"
                :rules="[rules.required]"
                clearable
                required
              ></v-autocomplete>
            </v-col>
            <v-col>
              <label>Start Date</label>

              <v-menu
                v-model="fromDateMenu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                full-width
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    class="text"
                    v-model="startDate"
                    placeholder="Select Start Date"
                    append-icon="event"
                    persistent-hint
                    dense
                    readonly
                    outlined
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="startDate"
                  no-title
                  @input="fromDateMenu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>

            <v-col>
              <label>End Date</label>

              <v-menu
                v-model="fromDateMenu1"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                full-width
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    class="text"
                    v-model="endDate"
                    placeholder="Select End Date"
                    append-icon="event"
                    persistent-hint
                    dense
                    readonly
                    outlined
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="endDate"
                  no-title
                  @input="fromDateMenu1 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </div>
          <div>
            <center>
              <v-btn color="success" class="mb-4" @click="EdtionWiseReport"
                >Fetch Data</v-btn
              >
            </center>
          </div>
        </div>
        
        <v-data-table
          :headers="headers"
          :search="search"
          :items="tabledata"
          class="elevation-5 ma-2"
         group-by="Editioncategory"
        >
          <template v-slot:top>
            <v-toolbar flat color="white" align="center" justify="center">
              <v-toolbar-title>Editoin Wise Report</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                append-icon="search"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
             <download-excel
                class="ma-3"
                v-if="tabledata.length != 0"
                color="primary"
                dark
                :data="tabledata"
                :fields="table_fields"
                worksheet="My Worksheet"
                name="Daily_Register.xls"
              >
                <v-btn color="success" dark class="mb-2">
                  <v-icon dark>mdi-file-excel</v-icon>
                </v-btn>
              </download-excel>
            </v-toolbar>
          </template>
          <template v-slot:item.date="{ item }">
            <span class="pointer" v-if="item.date != '' ">
                {{item.date.split("T")[0].split("-")[2]+"-"+item.date.split("T")[0].split("-")[1]+"-"+item.date.split("T")[0].split("-")[0]}}
                </span>
          </template>
        
        </v-data-table>
      </div>
    </v-card>
    
   
    <v-overlay :value="overlay"> Loading Please Wait.. </v-overlay>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import "@/assets/css/color.css"; // css needs to be imported for each dashboard
export default {
  data: () => ({
    overlay:false,
    series: null,
    search_book:"",
    fromDateMenu1: false,
    usertype: null,
    memberdata:[],
    valid: false,
    overlay: false,
    userid: "",
    bookitemid: "",
    selected: [],
    errorMsg: {
      roleLinkId: false,
    },
    rules: {
      required: (value) => !!value || "Required.",
    },
    tabledata: [],
    snackbar: false,
    snackbar_msg: "",
    color:"",
    search:"",
    org: "",
    dialog_book:false,
    dialog: false,
    fromDateMenu: false,
    fromDateVal: [],
    startDate: "",
    endDate: "",
    dailyData: [],
    series_data: [],
    usertype_data: [],
    minDate: "2020-01-05",
    maxDate: new Date(),
    dept_name: "",
    user_type: "",


    headers: [
      {
        text: "Sr no",
        align: "left",
        value: "srno",
      },
      {
        text: "Date Of Entry",
        align: "left",
        value: "date_of_entry",
      },
      {
        text: "Accession No",
        align: "left",
        value: "accesstion_no",
      },
       {
        text: "Title",
        align: "left",
        value: "title",
      },
      {
        text: "Author",
        align: "left",
        value: "author",
      },
       {
        text: "Publisher",
        align: "left",
        value: "publisher",
      },
       {
        text: "Editioncategory",
        align: "left",
        value: "Editioncategory",
      },
      {
        text: "Edition",
        align: "left",
        value: "edition_display",
      },
      {
        text: "Edtion Year",
        align: "left",
        value: "edition_year",
      },
       {
        text: "Volume",
        align: "left",
        value: "volume",
      },
      {
        text: "Cost",
        align: "left",
        value: "cost",
      },{
        text: "Net Cost",
        align: "left",
        value: "net_cost",
      }
      
    ],
   
      
    
  }),
  computed: {
    fromDateDisp() {
      return this.fromDateVal;
      // format/do something with date
    },
    filteredDesserts() {
      return this.dailyData.filter((d) => {
        return Object.keys(this.filters).every((f) => {
          return this.filters[f].length < 1 || this.filters[f].includes(d[f]);
        });
      });
    },
    dateRangeText() {
      return this.dates.join(" ~ ");
    },
  },

  mounted() {
         this.startDate = moment(String('1995-01-01')).format("YYYY-MM-DD");

   this.endDate = moment(String(new Date())).format("YYYY-MM-DD");
    this.onLoad();
  },
  methods: {
    EdtionWiseReport() {
      this.overlay = true;

      const data = {
        firstDate: moment(String(this.startDate)).format("DD-MM-YYYY"),
        lastDate: moment(String(this.endDate)).format("DD-MM-YYYY"),
        series_id: this.series,
        user_id: this.usertype,
      };
      axios
        .post("/Librarian/EdtionWiseReport", data)
        .then((res) => {
          if (res.data.msg == "200") {
            this.overlay = false;
            this.tabledata = res.data.data;
            if (this.tabledata.length == 0) {
              this.showSnackbar("#b71c1c", "No data available ");
            }
          }
        })
        .catch((error) => {
          this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
          window.console.log(error);
           this.overlay = false;
        })
        .finally(() => {});
    },
     close_dialog_book()
    {
      this.dialog_book= false;
    
    },
  
      exportexcel() {    
        $("#exceltable").table2excel({  
          name: "Worksheet Name",
          filename: "EditonWiseReport", //do not include extension
          fileext: ".xls" // file extension
        });        
      },
    onLoad() {
      this.overlay = true;
      axios
        .post("/Librarian/getdeptData")
        .then((res) => {
          if (res.data.msg == "200") {
            this.overlay = false;
            this.series_data = res.data.series_data;
            this.series_data.push({ name: "ALL", id: "ALL" });
            this.series = "ALL";
            this.usertype_data = res.data.usertype_data;
          }
        })
        .catch((error) => {
          this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
          window.console.log(error);
           this.overlay = false;
        })
        .finally(() => {
          // var overlay = false;
        });
    },
    showSnackbar(clr, msg) {
      this.snackbar = true;
      this.color = clr;
      this.snackbar_msg = msg;
    },
    close() {
      this.dialog = false;
      // this.$refs.form.reset()
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },
  },
};
</script>
<style scoped>
.v-text-field {
  padding-top: 0px;
  margin-top: 0px;
}
.v-icon-size {
  font-size: 20px;
}
.edit-avatar {
  transition: 0.3s;
  border: 1px solid #b0bec5;
}
.edit-avatar:hover {
  background-color: #b0bec5;
}
.edit-v-icon {
  padding-left: 6px;
  font-size: 15px;
}
.edit-v-icon:hover {
  color: white;
}
.fields {
  padding: 0px !important;
}
.cus-card {
  background: #d3d3d33d;
  margin: 1rem;
  padding: 1rem;
  border: 2px solid gray;
}
.c-label {
  font-weight: bold;
}
.add-section {
  display: block;
  margin: 1rem;
}
.text >>> .v-input__slot {
  background: white !important;
  border-color: hsla(190, 99%, 30%, 1) !important;
}
.elevation-1 /deep/ tr {
  white-space: nowrap !important;
}
.d-btn {
  display: flex;
  justify-content: flex-end;
  margin: 0.5rem;
}
.checkbox {
  margin: 1rem;
  border: 1px solid;
  background: #d3d3d380;
}
.checkbox .box {
  margin-top: 5px;
}
.pointer{
cursor: pointer;
}
.checkbox /deep/ .v-input--selection-controls {
  margin-top: 0px !important;
}
</style>
